<template>
    <div>
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="black"
        size="128"
        is-full-screen
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center"
            ><span style="color: #fff">
              {{ msg }}
            </span></v-flex
          >
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center pa-5>
        <v-flex xs12 v-if="exam.examAttendedCourseId">
          <span style="color: #000; font-family: poppinsbold; font-size: 25px">{{
            exam.examAttendedCourseId.courseName
          }}</span>
        </v-flex>
        <v-flex xs12 py-5>
          <v-layout wrap>
            <v-flex xs12 sm6 pa-2>
              <v-card height="235px">
                <v-layout wrap pa-5 justify-center>
                  <v-flex xs12 text-center>
                    <span style="font-family: poppinssemibold; font-size: 20px"
                      >Student Information</span
                    >
                  </v-flex>
                  <v-flex xs12 pt-10 v-if="exam.userId">
                    <span style="font-family: poppinsmedium; font-size: 16px">{{
                      exam.userId.name
                    }}</span>
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px">{{
                      exam.userId.email
                    }}</span>
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px"
                      >{{ exam.userId.countryCode }} {{ exam.userId.phone }}</span
                    >
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px">{{
                      exam.userId.userUniqueId
                    }}</span>
                    <br>
                    <span style="font-family: poppinsmedium; font-size: 16px">Total Mark : {{
                      exam.markAttainedFromEassy
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 pa-2>
              <v-card height="235px">
                <v-layout wrap pa-5 justify-center>
                  <v-flex xs12 text-center>
                    <span style="font-family: poppinssemibold; font-size: 20px"
                      >Exam Information</span
                    >
                  </v-flex>
                  <v-flex xs12 pt-5 v-if="exam">
                    <span style="font-family: poppinsmedium; font-size: 16px"
                      >Total Mark : {{ exam.totalMark }}</span
                    >
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px"
                      >Total Question Attempted :
                      {{ exam.totalQuestionsAttempted }}</span
                    >
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px"
                      >Total Wrong Answers : {{ exam.totalWrongAnswers }}</span
                    >
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px"
                      >Total MCQ Question Attempted :
                      {{ exam.totalMcqQuestionsAttempted }}</span
                    >
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px"
                      >Total True/False Question Attempted :
                      {{ exam.totalTrueOrFalseQuestionsAttempted }}</span
                    >
                    <br />
                    <span style="font-family: poppinsmedium; font-size: 16px"
                      >Exam Attended Date : {{ exam.date.slice(0, 10) }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 py-5>
              <span style="color: #000; font-family: poppinsbold; font-size: 20px"
                >Evaluate Answers</span
              >
            </v-flex>
            <v-flex xs12 v-for="(item, i) in answer" :key="i">
              <v-layout wrap>
                <v-flex xs12 py-5>
                  <span style="font-family: poppinssemibold; font-size: 18px"
                    >{{ i + 1 }}. {{ item.questionId.question }}</span
                  >
                </v-flex>
                <v-flex xs12 sm12 pa-2>
                  <v-card outlined color="#cfcdca">
                    <v-layout wrap pa-5>
                      <v-flex xs12>
                        <span
                          style="font-family: poppinssemibold; font-size: 18px"
                          >Student Answer</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-2>
                        <span
                          style="font-family: poppinsregular; font-size: 16px"
                          >{{ item.answer }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <!-- <v-flex xs12 sm6 pa-2>
                  <v-card outlined color="#3CB043">
                    <v-layout wrap pa-5>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: poppinssemibold;
                            font-size: 18px;
                            color: white;
                          "
                          >Correct Answer</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-2>
                        <span
                          style="
                            font-family: poppinsregular;
                            font-size: 16px;
                            color: white;
                          "
                          >{{ item.correctAnswer }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex> -->
                <v-flex
                  xs12
                  pt-2
                  style="font-family: poppinsmedium; font-size: 18px"
                >
                 Student Mark : {{ item.markAttained }}
                </v-flex>
              </v-layout>
              <v-flex xs12><hr /></v-flex>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showsnackbar: false,
        msg: null,
        timeout: 5000,
        exam: "",
        answer: [],
        marks:[]
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          url: "/admin/exam/view",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            examId: this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.exam = response.data.exam;
            this.answer = response.data.questionAnswers;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
  };
  </script>